import React, { useEffect, useState } from "react";
import localStorageHelper from "../../helpers/localStorage.js";

import { useLocation, useNavigate } from "react-router-dom";

const urlParamsToObject = (urlParams) => {
	const obj = {};

	// Iterate over all entries in URLSearchParams
	for (let [key, value] of urlParams.entries()) {
		// If the key already exists, make it an array
		if (obj[key]) {
			if (!Array.isArray(obj[key])) {
				obj[key] = [obj[key]];
			}
			obj[key].push(value);
		} else {
			obj[key] = value;
		}
	}

	return obj;
}

const AutologinFourCo = () => {
	const [isLoginInProgress, setIsLoginInProgress] = useState("");
	const [isLoginFailed, setIsLoginFailed] = useState("");

	const allowableParams = ["access", "refresh", "username"];

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setIsLoginInProgress(true);
		setIsLoginFailed(false);

		let ok = true;
		const search = location.search;
		const searchParams = new URLSearchParams(search);
		const parameters = urlParamsToObject(searchParams);

		if (Object.keys(parameters).length > 0) {
			// Checking available parameters
			if (Object.keys(parameters).length === allowableParams.length) {
				allowableParams.forEach((value) => {
					if (parameters[value] === undefined) {
						ok = false;
					}
				});

				if (ok) {
					localStorageHelper.setAccessToken(parameters.access);
					localStorageHelper.setRefreshToken(parameters.refresh);
					localStorageHelper.setIsUserLoggedIn(true);
					localStorageHelper.setUsername(parameters.username);
					navigate("/home", { replace: true });
				} else {
					navigate("/login", { replace: true });
				}
			}
		}
	}, []);

	return <></>;
};

export default AutologinFourCo;
