import { faCircleNotch, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from "querystring";
import React from 'react';
import { Alert, Button, Form, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import request from '../../helpers/request.js';
import { linkDeviceToConfiguration } from '../../services/devices.js';
import { addService } from "../../services/services.js";
import Dialog from "../Common/Dialog/Dialog.jsx";
import CustomerFormData from './Wizard/CustomerFormData';
import DeptFormData from './Wizard/DeptFormData';
import FlagsFormData from './Wizard/FlagsFormData';
import ReceiptFormData from './Wizard/ReceiptFormData';
import RtActivationFormData from './Wizard/RtActivationFormData';
import TaxFormData from './Wizard/TaxFormData';
export default class Edit extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
			deviceType              : null,
            wizardLowestLevel       : 0,
            wizardLevel             : 0,
            wizardHighestLevel      : 5,
            isFormValid             : false,
            isStepValidated         : false,
            isCreateMod             : true,
            isConfigEditInProgress  : false,
            configuration_data      : null,
            stepProgressArray : {
                customer_data       : false,
                rt_activation_data  : false,
                receipt_data        : false,
                tax_data            : false,
                dept_data           : false,
                flags_data          : false
            },
            stepValidationArray: [false, false, false, false, false, false],
            activeTab               : '1',
            showResponse            : false,
            isSuccessEditAlertVisible: false,
            isNotSuccessfulEditAlertVisible: false,
            isSuccessDuplicationAlertVisible: false,
			responseAlertMessage: "",
			isSaveClicked: false,
			showDialog: false
            // images_flag_data : []
        }
		this.myRef = React.createRef();
    }

    componentDidMount() {

        this.setState({
            is_configuration_linked_to_devices: this.props.data.is_linked_to_devices,
            configuration_data: this.props.data.data,
			deviceType: this.props.data.data.activation_module.device_type || null,
			customerVatCode: this.props.data.data.customer_module.vat_code || null,
        });
    }

    componentDidUpdate(prevProps) {

        if(this.props.data !== prevProps.data){

            // const image_flag = this.props?.data?.data?.customer_module?.receipt_data?.images.filter((img) => img.id !== 1 && img.id !== 2) ?? []
            
            this.setState({
                configuration_data: this.props.data.data,
                // images_flag_data: [...image_flag]
            });

        }

    }
    

    stepValidatorHandler = (isValid, tabId) => {
        let stepValidationArrayStatus = this.state.stepValidationArray;
        let isFormValid;

        stepValidationArrayStatus[tabId - 1] = isValid;
        isFormValid = stepValidationArrayStatus.every(function(step){
            return step === true;
        })
        this.setState({
            isStepValidated: isValid,
            isFormValid: isFormValid
        });
    }

    incrementWizardLevel = () => {
        this.setState(state => ({
            wizardLevel: state.wizardLevel + 1
        }))
    }

    decrementWizardLevel = () => {
        this.setState(state => ({
            wizardLevel: state.wizardLevel - 1
        }))
    }

    saveCustomerData = (data) => {

        let tmp_data = this.state.configuration_data;
        tmp_data.customer_module = data;

        tmp_data.activation_module.activity_vatcode = data.vat_code;

        let tmp_progress = this.state.stepProgressArray;
        tmp_progress.customer_data = true;

        this.setState({
            configuration_data: tmp_data,
            stepProgressArray : tmp_progress
        })
    }

    saveActivationData = (data) => {

        this.setState({
            configuration_data: {
                ...this.state.configuration_data, 
                activation_module : data,
            },
            stepProgressArray : {
                ...this.state.stepProgressArray,
                rt_activation_data : true
            }
        })

    }

    saveReceiptFormData = (data) => {
        this.setState({
            receipt_module : data
        });
        
    }

    saveTaxData = (data) => {

        this.setState({
            configuration_data: {
                ...this.state.configuration_data, 
                tax_module : data,
            },
            stepProgressArray : {
                ...this.state.stepProgressArray,
                tax_data : true
            }
        })

    }

    saveFlagData = (flag_data, operational_data, images_flag) => {
        // let receipt_module;
        // if(this.state.receipt_module){
        //     receipt_module = {...this.state.receipt_module}
        // }else{
        //     receipt_module = {...this.state.configuration_data?.customer_module?.receipt_data}
        // }

        if(this.state.configuration_data?.activation_module?.device_type === "2"){
			// flag_data = {...flag_data, images: images_flag }
			/*
            let receipt_module_images = receipt_module?.images ?? [];

            const flagDataImages = receipt_module_images.filter((img) => img.id !== 1 && img.id !== 2) ?? [];
    
            if(flagDataImages.length === 0){
                images_flag.forEach((img_flag) => receipt_module.images.push(img_flag));
            }else{
                receipt_module.images = receipt_module_images.map((img) => {
                    if(img.id === 3 || img.id === 4 || img.id === 5){
                        return images_flag.find((img_param) => img_param.id === img.id)
                    }else{
                        return img
                    }
                })
            }
			*/
        }

        this.setState({
            configuration_data: {
                ...this.state.configuration_data, 
                flag_module : { settings: flag_data, images: images_flag },
                operational_module : operational_data
            },
            stepProgressArray : {
                ...this.state.stepProgressArray,
                flags_data : true
            },
            images_flag_data: [...images_flag]
        })
    }

    saveDeptData = (dept_data) => {

        let config_data = this.state.configuration_data;
        let progress_data = this.state.stepProgressArray;

        config_data.dept_data = dept_data;
        progress_data.dept_data = true;

        this.setState({
            configuration_data: config_data,
            stepProgressArray : progress_data
        });

    }

    saveAtecoCodes = ( atecoCodes ) => {

        this.setState({
            configuration_data: {
                ...this.state.configuration_data,
                ateco_codes : atecoCodes
            }
        })

    }

    toggle = (idTab) => {

        this.setState({
            activeTab : idTab
        })

    }

    onDismissDuplicateSuccessAlert = () => {
        this.setState({ 
            isSuccessDuplicationAlertVisible: false 
        });
    }

    onDismissSuccessAlert = () => {
        this.setState({ 
            isSuccessEditAlertVisible: false 
        });
    }

    onDismissErrorAlert = () => {
        this.setState({ 
            isNotSuccessfulEditAlertVisible: false 
        });
    }

	updateDeviceTypeValue = (value) => {
        this.setState({
            deviceType: value
        })
		// Update device_type using spread operator
		this.setState({
			configuration_data: {
				...this.state.configuration_data, 
				activation_module : {...this.state.configuration_data.activation_module, device_type: value},
			}
		})
	}

	updateCustomerVatCode = (value) => {
		this.setState({
			customerVatCode: value
		})
	}

    renderContent = () => {
        if(!this.state.configuration_data)
            return

        let customer_data = this.state.configuration_data?.customer_module ?? undefined;
        let content =   
            <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={this.state.activeTab === '1' ? 'active' : null}
                        onClick={() => { this.toggle('1'); }}
                    >
                        <span> Esercizio</span>
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        className={this.state.activeTab === '2' ? 'active' : null}
                        onClick={() => { this.toggle('2'); }}
                    >
                        
                        <span> Attivazione </span>
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        className={this.state.activeTab === '3' ? 'active' : null}
                        onClick={() => { this.toggle('3'); }}
                    >
                        
                        <span> Scontrino</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={this.state.activeTab === '4' ? 'active' : null}
                        onClick={() => { this.toggle('4'); }}
                    >
                        
                        <span> Aliquote</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={this.state.activeTab === '5' ? 'active' : null}
                        onClick={() => { this.toggle('5'); }}
                    >
                        
                        <span> Reparti</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={this.state.activeTab === '6' ? 'active' : null}
                        onClick={() => { this.toggle('6'); }}
                    >
                        
                        <span> Sistema</span>
                    </NavLink>
                </NavItem>                                    
                </Nav>
                <fieldset disabled={this.state.is_configuration_linked_to_devices === 1 && !this.props.isDuplicationAction ? true : false }>
                    <TabContent activeTab={this.state.activeTab}>
                    
                        <TabPane tabId="1">                                        
                            <CustomerFormData 
                                tabId={1}
                                mod={this.state.stepProgressArray.customer_data === false ? 'edit' : 'review'} 
                                key={2} 
                                saveCustomerData={this.saveCustomerData} 
                                data={ this.state.configuration_data.customer_module || undefined } 
                                isStepValidated={ this.stepValidatorHandler } 
								updateCustomerVatCode={ this.updateCustomerVatCode }
                            />
                        </TabPane>

                        <TabPane tabId="2">
                            <RtActivationFormData
                                tabId={2}
                                saveRtActivationData = {this.saveActivationData}
                                mod={this.state.stepProgressArray.rt_activation_data === false ? 'edit' : 'review'}
                                data = { this.state.configuration_data.activation_module} 
                                isStepValidated={ this.stepValidatorHandler } 
								changeDevice={this.updateDeviceTypeValue}
								isFromRTModal={this.props.isFromRTModal}
								activityVatCode={this.state.customerVatCode}
                            />
                        </TabPane>

                        <TabPane tabId="3">
                            <ReceiptFormData 
                                tabId={3}
                                data = {customer_data ? customer_data.receipt_data : undefined } 
                                activity_name={customer_data ? customer_data.activity_name : undefined}
                                business_name={customer_data ? customer_data.business_name : undefined}
                                vat_code={customer_data ? customer_data.vat_code : undefined}
                                fiscal_code={customer_data ? customer_data.fiscal_code : undefined}
                                address={customer_data ? customer_data.address : undefined}
                                zip_code={customer_data ? customer_data.zip_code : undefined}
                                deviceType={this.state.deviceType ?? ""}
                                isStepValidated={ this.stepValidatorHandler }
                                saveReceiptFormData = { this.saveReceiptFormData } 
                                isEnabledForEdits={(this.props.isDuplicationAction && this.state.is_configuration_linked_to_devices) || !this.state.is_configuration_linked_to_devices}
                            />
                        </TabPane>

                        <TabPane tabId="4">
                            <TaxFormData
                                tabId={4}
                                saveTaxData = {this.saveTaxData}
                                mod={this.state.stepProgressArray.tax_data === false ? 'edit' : 'review'}
                                data = { this.state.configuration_data.tax_module || undefined } 
                                isStepValidated={ this.stepValidatorHandler } 
                            />  
                        </TabPane>

                        <TabPane tabId="5">
                            <DeptFormData
                                atecoCodes={this.state.configuration_data.ateco_codes || []}
                                saveAtecoCodes={this.saveAtecoCodes}
                                tabId={5}
                                mod={this.state.stepProgressArray.dept_data === false ? 'edit' : 'review'} 
                                isStepValidated={ this.stepValidatorHandler } 
                                saveDeptData={this.saveDeptData}
                                data={this.state.configuration_data.dept_programming || undefined }
                                taxes={this.state.configuration_data.tax_module || []} 
								deviceType={this.state.deviceType ?? ""}
                            /> 
                        </TabPane>

                        <TabPane tabId="6">
                            <FlagsFormData 
                                tabId={6}
                                saveData = {this.saveFlagData}
                                mod={this.state.stepProgressArray.flags_data === false ? 'edit' : 'review'}
                                flag_data = { this.state.configuration_data.flag_module ? this.state.configuration_data.flag_module : undefined } 
                                operational_data = {this.state.configuration_data.operational_module}
								deviceType={this.state.deviceType ?? ""}
                                isStepValidated={ this.stepValidatorHandler }
                                isEnabledForEdits={(this.props.isDuplicationAction && this.state.is_configuration_linked_to_devices) || !this.state.is_configuration_linked_to_devices}
                            /> 
                        </TabPane>
                </TabContent>
            </fieldset>
        </React.Fragment>;

    return content

    }


	onSave = () => {
		this.setState({
			isSaveClicked: true
		});
		if(!this.props.hasPlan) {
			this.setState({
				showDialog: true
			});
		}
		else {
			this.saveConfiguration();
		}
	}

    saveConfiguration = () => {

        const receipt_item_max_length = this.state.configuration_data?.activation_module?.device_type === "2" ? 32 : 48;

        let customer_module = this.state.configuration_data.customer_module;
        let customerModuleWithoutReceiptData = customer_module;
        let receipt_module;
        if(this.state.receipt_module){
            receipt_module = {...this.state.receipt_module}
        }else{
            receipt_module = {...this.state.configuration_data?.customer_module?.receipt_data}
        }

        customer_module.receipt_data = {
            ...receipt_module,
            header_section : receipt_module.header_section.map((header_row) => {
                if(header_row.align === "center"){
                    const rowLength = header_row.value.length
                    if(rowLength > 0 && rowLength < receipt_item_max_length){
                        const remainingSpace  = receipt_item_max_length - rowLength;
                        const spaceBySide     = Math.floor(remainingSpace/2);
                        const blankChars      = " ".repeat(spaceBySide);
                        return {...header_row, value: blankChars + header_row.value + blankChars}
                    }
                }
    
                return header_row
            }),
            footer_section : receipt_module.footer_section.map((footer_row) => {
                if(footer_row.align === "center"){
                    const rowLength = footer_row.value.length
                    if(rowLength > 0 && rowLength < receipt_item_max_length){
                        const remainingSpace  = receipt_item_max_length - rowLength;
                        const spaceBySide     = Math.floor(remainingSpace/2);
                        const blankChars      = " ".repeat(spaceBySide);
                        return {...footer_row, value: blankChars + footer_row.value + blankChars}
                    }
                }
    
                return footer_row
            })
        };

        let configurationId = this.props.configurationId;
        let isDuplicationActionSuccessful;
        let isEditSuccessful;
        let operationType;
        let newConfigurationId;

        this.setState({
            isConfigEditInProgress: true
        }, () => {
            
            let device_url      = `${process.env.REACT_APP_RT_DEVICES_ENDPOINT}/${this.props.idDevice}${process.env.REACT_APP_RT_CONFIGS_ENDPOINT}/${configurationId}`;
            let config_url      = `${process.env.REACT_APP_RT_CONFIGS_ENDPOINT}/${configurationId}`;
            let duplication_url = `${process.env.REACT_APP_RT_CONFIGS_ENDPOINT}`;

            let url        = ( this.props.isDuplicationAction && !this.props.isFromRTModal ? duplication_url : (this.props.idDevice ? device_url : config_url) );
            request(
                process.env.REACT_APP_API_RT_SERVICE_HOST,
                {
                    method: "POST",
                    url:    url,
                    body:   queryString.stringify({ 
                        customer_module     : JSON.stringify(customer_module), 
                        activation_module   : JSON.stringify(this.state.configuration_data.activation_module),
                        tax_module          : JSON.stringify(this.state.configuration_data.tax_module),
                        dept_programming    : JSON.stringify(this.state.configuration_data.dept_data),
                        operational_module  : JSON.stringify(this.state.configuration_data.operational_module),
                        flag_module         : JSON.stringify(this.state.configuration_data.flag_module),
                        ateco_codes         : JSON.stringify(this.state.configuration_data.ateco_codes || [])
                    }),
                    config: { headers : { 'Authorization' : `Bearer ${localStorage.getItem('access_token')}` }}
                }  
            ).then((response) => {
            
                operationType = response.data.type;
                newConfigurationId = response.data.result.code;

                if(response.status === 200)
                {
                    // from autoconfig with model selected 
                    if(this.props.isDuplicationAction && !this.props.isFromRTModal && this.props.idDevice){
                        const idConfiguration = response.data.idConfiguration
                        linkDeviceToConfiguration(this.props.idDevice, idConfiguration);
                    }

                    if(this.props.isDuplicationAction && !this.props.isFromRTModal)
                    {
                        isDuplicationActionSuccessful = true;
                        isEditSuccessful = false;
                        
                    }
                    else 
                    {
                        if(operationType === "update"){
                            isEditSuccessful = true;
                            isDuplicationActionSuccessful = false;
                            
                        }  
                        else if(operationType === "insert"){
                            
                            isEditSuccessful = true;
                            isDuplicationActionSuccessful = false;
                            if(typeof this.props.closeEditModalInInsertOperation != "undefined"){
                                this.props.closeEditModalInInsertOperation(true, newConfigurationId, customerModuleWithoutReceiptData);
                            }
                        }
                    }

                    if(!this.props.isFromRTModal) this.props.reloadConfigurationList();
                    
                } 
                else {
                    isEditSuccessful = false;
                }
				this.myRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }); // used to scroll to top alert container
                this.setState({
                    isSuccessDuplicationAlertVisible: isDuplicationActionSuccessful,
                    isSuccessEditAlertVisible       : isEditSuccessful,
                    isNotSuccessfulEditAlertVisible : isDuplicationActionSuccessful ? false : !isEditSuccessful,
                    isConfigEditInProgress          : false,
                    operationType                   : operationType,
                    configurationId                 : newConfigurationId
                })
            })
            .catch(err => {
                isEditSuccessful = false;
				this.myRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }); // used to scroll to top alert container
                this.setState({ 
					isConfigEditInProgress          : isEditSuccessful,
                    isSuccessEditAlertVisible       : isEditSuccessful,
                    isNotSuccessfulEditAlertVisible : !isEditSuccessful,
					responseAlertMessage            : err.data[0].error
                }, () => {
                    console.log(err);
                });
            })
        })
    }
    render(){
        return (
            <React.Fragment>
			<div id="alertContainer" ref={this.myRef}>
				<Alert color="success" isOpen={this.state.isSuccessDuplicationAlertVisible}>
					Modello creato con successo
				</Alert>
				<Alert color="success" isOpen={this.state.isSuccessEditAlertVisible}>
					Modello modificato con successo
				</Alert>
				<Alert color="danger" isOpen={this.state.isNotSuccessfulEditAlertVisible}>
					Non è stato possibile applicare le modifiche al modello
					{
						this.state.responseAlertMessage !== "" &&
							<ul>
								<li>{this.state.responseAlertMessage}</li>
							</ul>
					}
				</Alert>
			</div>

            <Form>
                {this.renderContent()}
            </Form>
            <hr />
            <div className="float-right">
                { this.state.isConfigEditInProgress ? 
                <FontAwesomeIcon icon={faCircleNotch} className="float-left mr-1" spin size="2x"/> : '' }
                {' '}
                { 
                    this.state.is_configuration_linked_to_devices === 1 && !this.props.isDuplicationAction ? "" : 
                    <Button color="success" disabled={this.state.isConfigEditInProgress || !this.state.isFormValid} onClick={this.onSave}>
                        <FontAwesomeIcon icon={faSave} />{' '}Salva
                    </Button>
                }
				<Dialog toggle={ () => {
					this.setState({
						showDialog: !this.state.showDialog
					})
				}} 
				onSuccess={() => {
						try {
							addService(this.props.idDevice, "PLAN");
							this.saveConfiguration();
						}
						catch(error) {
							console.error(error);
						}
					}
				} 
				onCancel={ this.saveConfiguration }
				isOpen={this.state.showDialog} 
				text={"Su questo dispositivo non è attivo il piano assistenza, vuoi attivarlo?"} />
            </div>
        </React.Fragment>
        );
    }
}