import queryString from "querystring";
import React from 'react';
import { Alert, Form } from "reactstrap";
import deviceHelper from '../../helpers/device.js';
import request from '../../helpers/request.js';
import { addService } from "../../services/services.js";
import Dialog from "../Common/Dialog/Dialog.jsx";
import ConfirmationModal from './Modal/ConfirmationModal';
import ResponseModal from './Modal/ResponseModal';
import CustomerFormData from './Wizard/CustomerFormData';
import DeptFormData from './Wizard/DeptFormData';
import FlagsFormData from './Wizard/FlagsFormData';
import NavigationButtons from './Wizard/NavigationButtons';
import ReceiptFormData from './Wizard/ReceiptFormData';
import RtActivationFormData from './Wizard/RtActivationFormData';
import TaxFormData from './Wizard/TaxFormData';

export default class Create extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
			deviceType              : 1,
            isConfigSaveInProgress:     false,
            isConfirmationModalOpen:    false,
            isAlertErrorVisible:        false,
            isResponseModalOpened:      false,
            wizardLowestLevel: 0,
            wizardLevel: 0,
            wizardHighestLevel: 5,
            isFormValid: false,
            isStepValidated: false,
            isCreateMod : true,
            configuration_data : {},
            stepProgressArray : {
                customer_data       : false,
                rt_activation_data  : false,
                receipt_data        : false,
                tax_data            : false,
                dept_data           : false,
                flags_data          : false
            },
            idConfiguration: 0,
            device_matricola: undefined,
			responseAlertMessage: "",
			hasPlan: null,
			isSaveClicked: false,
			showDialog: false
        }
		this.myRef = React.createRef();
    }


    componentDidMount(){
        let devType;
        if(this.props.matricolaAutoConfig) {
            devType = deviceHelper.fromFiscalNumberToDeviceType(this.props.matricolaAutoConfig);
            this.setState({
                device_matricola: this.props.matricolaAutoConfig,
                deviceType: devType === 2 ? "2" : "1"
            })
        }else if(this.props.fiscal_number){
            devType = deviceHelper.fromFiscalNumberToDeviceType(this.props.fiscal_number);
            this.setState({
                device_matricola: this.props.fiscal_number,
                deviceType: devType === 2 ? "2" : "1"
            })
        }
		if(this.props.hasPlan) {
			this.setState({
				hasPlan: this.props.hasPlan
			});
		}
    }

    componentDidUpdate(prevProps){
        if(prevProps.matricolaAutoConfig === undefined && this.props.matricolaAutoConfig !== undefined){
            this.setState({
                device_matricola: this.props.matricolaAutoConfig
            })
        }else if(prevProps.fiscal_number === undefined && this.props.fiscal_number !== undefined){
            this.setState({
                device_matricola: this.props.fiscal_number
            })
        }
    }

	updateDeviceTypeValue = (value) => {
		this.setState({
			deviceType: value
		})

		// Update device_type using spread operator
		this.setState({
			configuration_data: {
				...this.state.configuration_data, 
				activation_module : {...this.state.configuration_data.rt_activation_module, device_type: value},
			}
		})
	}

	updateCustomerVatCode = (value) => {
		this.setState({
			customerVatCode: value
		})
	}

    stepValidatorHandler = (isValid) => {
        this.setState({
            isStepValidated: isValid
        }, () => {
            if(this.state.wizardHighestLevel === this.state.wizardLevel)
            {
                this.setState({
                    isFormValid: this.state.isStepValidated
                })
            }
        });
    }

    incrementWizardLevel = () => {

        const valid = this.state.wizardLevel === 4 ? true : false;

        this.setState(state => ({
            wizardLevel: state.wizardLevel + 1,
            isStepValidated: valid
        }), () => {

            valid && this.stepValidatorHandler( valid );

        })
    }

    decrementWizardLevel = () => {
        this.setState(state => ({
            wizardLevel: state.wizardLevel - 1,
            isStepValidated: true
        }))
    }

    saveCustomerData = (data) => {

        this.setState({
            configuration_data: {
                ...this.state.configuration_data, 
                customer_module : data
            }
        });
    }

    saveRtActivationData = (data) => {
        this.setState({
            configuration_data: {
                ...this.state.configuration_data,
                rt_activation_module : data
            },
            mod: 'create'
        });
    }

    saveReceiptFormData = (data) => {
        let configurationData = this.state.configuration_data;
        configurationData.customer_module.receipt_data = data;

        this.setState({
            configuration_data : configurationData,
            mod: 'create'
        });
    }

    saveTaxData = (data) => {

        this.setState({
            configuration_data : {
                ...this.state.configuration_data, 
                tax_data : data
            },
            mod: 'create'
        })

    }

    saveDeptData = ( data, atecoCodes) => {

        this.setState({
            configuration_data : {
                ...this.state.configuration_data,
                dept_data: data,
                ateco_codes: atecoCodes
            },
            mod: 'create'
        });
    }

    saveAtecoCodes = ( atecoCodes ) => {

    }

    saveFlagData = (flag_data, operational_data, images_flag) => {

        this.setState({
            configuration_data : {
                ...this.state.configuration_data, 
                flag_data :  { settings: flag_data, images: images_flag },
                operational_data : operational_data
            },
            stepProgressArray : {
                ...this.state.stepProgressArray,
                    receipt_data : true
            },
        })

    }

    toggleConfirmationModal = () => {
        this.setState(prevState => ({
            isConfirmationModalOpen: !prevState.isConfirmationModalOpen
        }));
    }

    //Resets wizard to original state
    createNewConfiguration = () => {
        this.setState({
            isConfigSaveInProgress:     false,
            isConfirmationModalOpen:    false,
            isAlertErrorVisible:        false,
            isResponseModalOpened:      false,
            wizardLowestLevel: 0,
            wizardLevel: 0,
            wizardHighestLevel: 5,
            isFormValid: false,
            isStepValidated: false,
            isCreateMod : true,
            configuration_data : {},
            stepProgressArray : {
                customer_data       : false,
                rt_activation_data  : false,
                receipt_data        : false,
                tax_data            : false,
                dept_data           : false,
                flags_data          : false
            },
            idConfiguration: 0
        })
    }

    goBackToConfigurationList = () => {
        this.setState(prevState => ({
            isResponseModalOpened: !prevState.isResponseModalOpened
        }), () => {
            this.props.goToList()
        });
    }

	onSave = () => {
		this.setState({
			isSaveClicked: true
		});
		if(!this.state.hasPlan) {
			this.setState({
				showDialog: true
			});
		}
		else {
			this.saveFormData();
		}

	}

    saveFormData = () => {

        const receipt_item_max_length = this.state.configuration_data?.rt_activation_module?.device_type === "2" ? 32 : 48;

        const currentCustomerModule = {
            ...this.state.configuration_data.customer_module,
            receipt_data: {
                ...this.state.configuration_data.customer_module.receipt_data,
                header_section : this.state.configuration_data.customer_module?.receipt_data?.header_section.map((header_row) => {
                    if(header_row.align === "center"){
                        const rowLength = header_row.value.length
                        if(rowLength > 0 && rowLength < receipt_item_max_length){
                            const remainingSpace  = receipt_item_max_length - rowLength;
                            const spaceBySide     = Math.floor(remainingSpace/2);
                            const blankChars      = " ".repeat(spaceBySide);
                            return {...header_row, value: blankChars + header_row.value + blankChars}
                        }
                    }
        
                    return header_row
                }),
                footer_section : this.state.configuration_data.customer_module?.receipt_data?.footer_section.map((footer_row) => {
                    if(footer_row.align === "center"){
                        const rowLength = footer_row.value.length
                        if(rowLength > 0 && rowLength < receipt_item_max_length){
                            const remainingSpace  = receipt_item_max_length - rowLength;
                            const spaceBySide     = Math.floor(remainingSpace/2);
                            const blankChars      = " ".repeat(spaceBySide);
                            return {...footer_row, value: blankChars + footer_row.value + blankChars}
                        }
                    }
        
                    return footer_row
                })
            }
        }

        this.setState({
            isFormCompleted: true,
            isConfigSaveInProgress: true
        }, () => {
            request(
                process.env.REACT_APP_API_RT_SERVICE_HOST,
                {
                    method: "POST",
                    url:    process.env.REACT_APP_RT_CONFIGS_ENDPOINT,
                    body:   queryString.stringify({ 
                        customer_module :   JSON.stringify(currentCustomerModule), 
                        activation_module:  JSON.stringify(this.state.configuration_data.rt_activation_module),
                        tax_module:         JSON.stringify(this.state.configuration_data.tax_data),
                        dept_programming:   JSON.stringify(this.state.configuration_data.dept_data),
                        operational_module: JSON.stringify(this.state.configuration_data.operational_data),
                        flag_module:        JSON.stringify(this.state.configuration_data.flag_data),
                        ateco_codes:        JSON.stringify(this.state.configuration_data.ateco_codes)
                    }),
                    config: { headers : { 'Authorization' : `Bearer ${localStorage.getItem('access_token')}` }}
                }  
            ).then(response => {
                if(response.status === 200){

                    const conficurationCode = response.data.result;

                    if(typeof this.props.extraActionToPerformOnSave !== 'undefined') this.props.extraActionToPerformOnSave(this.state.device_matricola, response.data.idConfiguration);

                    this.setState({ 
                        isConfigSaveInProgress:     false,
                        isConfirmationModalOpen:    false,
                        isAlertErrorVisible:        false,
                        isResponseModalOpened:      true,
                        idConfiguration:            conficurationCode
                    });
                } 
                else {
                    this.setState({ 
                        isConfigSaveInProgress:  false,
                        isConfirmationModalOpen: false,
                        isAlertErrorVisible:     true,
                        isResponseModalOpened:   false
                    });
                }
            })
            .catch(err => {
				this.myRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                this.setState({ 
                    isConfigSaveInProgress:  false,
                    isConfirmationModalOpen: false,
                    isAlertErrorVisible:     true,
                    isResponseModalOpened:   false,
					responseAlertMessage: err.data[0].error
                }, () => {
                    console.log(err);
                });
            })
        })
    }

    renderWizardComponent = (wizardLevel) => {
        let customer_data = this.state.configuration_data.customer_module || undefined;

        switch(wizardLevel){
            case 1:
                return  <RtActivationFormData 
							changeDevice={this.updateDeviceTypeValue}
                            saveRtActivationData={ this.saveRtActivationData } 
                            data={ this.state.configuration_data.rt_activation_module || undefined } 
                            activityVatCode={ this.state.configuration_data.customer_module.vat_code }
                            isStepValidated={ this.stepValidatorHandler }
                            matricolaAutoConfig={this.state.device_matricola ?? undefined}
                        />;
                
            case 2:
                return <ReceiptFormData 
                        isStepValidated={ this.stepValidatorHandler } 
                        saveReceiptFormData={this.saveReceiptFormData}
                        data={ customer_data ? customer_data.receipt_data : undefined }
                        activity_name={customer_data ? customer_data.activity_name : undefined}
                        business_name={customer_data ? customer_data.business_name : undefined}
                        vat_code={customer_data ? customer_data.vat_code : undefined}
                        fiscal_code={customer_data ? customer_data.fiscal_code : undefined}
                        address={customer_data ? customer_data.address : undefined}
                        zip_code={customer_data ? customer_data.zip_code : undefined}
                        deviceType={this.state.deviceType ?? ""}
                        isEnabledForEdits={true}
                    />;
;
            case 3:
                return  <TaxFormData 
                            isStepValidated={ this.stepValidatorHandler } 
                            saveTaxData={this.saveTaxData} 
                            data={ this.state.configuration_data.tax_data || undefined }
                        />;
            
            case 4:
                return <DeptFormData
                            atecoCodes={this.state.configuration_data.ateco_codes || []} 
                            isStepValidated={ this.stepValidatorHandler } 
                            saveAtecoCodes={this.saveAtecoCodes}
                            saveDeptData={this.saveDeptData}
                            data={this.state.configuration_data.dept_data || undefined }
                            taxes={this.state.configuration_data.tax_data } 
							deviceType={this.state.deviceType ?? ""}
							/> ;
            case 5:
                return <FlagsFormData 
                            isStepValidated={ this.stepValidatorHandler } 
                            flag_data = {this.state.configuration_data.flag_data}
                            operational_data = {this.state.configuration_data.operational_data}
                            deviceType={this.state.deviceType ?? ""}
                            images_data = {customer_data ? customer_data.receipt_data : undefined } 
                            saveData = {this.saveFlagData}
                            isEnabledForEdits={true}
                        /> ;
            default: //starting wizard point
                return <CustomerFormData 
                        mod={'create'} 
                        key={2} 
                        saveCustomerData={this.saveCustomerData} 
                        data={ this.state.configuration_data.customer_module || undefined } 
                        isStepValidated={ this.stepValidatorHandler } 
						updateCustomerVatCode={ this.updateCustomerVatCode }
                    />;
        }
    }

    render(){
        let customer_data = this.state.configuration_data.customer_module || undefined;
        return (
            <React.Fragment>
				<div id="alertContainer" ref={this.myRef}>
					<Alert color="danger" isOpen={this.state.isAlertErrorVisible}>
						Si è verificato un errore durante il salvataggio del modello
						{
						this.state.responseAlertMessage !== "" &&
							<ul>
								<li>{this.state.responseAlertMessage}</li>
							</ul>
						}
					</Alert>
				</div>
                <NavigationButtons 
                    wizardLevel={this.state.wizardLevel} 
                    wizardLowestLevel={this.state.wizardLowestLevel} 
                    wizardHighestLevel={this.state.wizardHighestLevel}
                    decrementWizardLevel={this.decrementWizardLevel}
                    incrementWizardLevel={this.incrementWizardLevel}
                    isStepValidated={this.state.isStepValidated}
                    toggleConfirmationModal={this.toggleConfirmationModal}
                    isFormValid={this.state.isFormValid}
                />
                <Form className="container">
                    { this.renderWizardComponent(this.state.wizardLevel) }
                </Form>
                <hr />
                <NavigationButtons 
                    wizardLevel={this.state.wizardLevel} 
                    wizardLowestLevel={this.state.wizardLowestLevel} 
                    wizardHighestLevel={this.state.wizardHighestLevel}
                    decrementWizardLevel={this.decrementWizardLevel}
                    incrementWizardLevel={this.incrementWizardLevel}
                    isStepValidated={this.state.isStepValidated}
                    saveButtonSize={"lg"}
                    toggleConfirmationModal={this.toggleConfirmationModal}
                    isFormValid={this.state.isFormValid}
                />
                <ConfirmationModal 
                    isModalOpen={this.state.isConfirmationModalOpen} 
                    toggleConfirmationModal={this.toggleConfirmationModal} 
                    saveFormData={this.onSave} 
                    isConfigSaveInProgress={this.state.isConfigSaveInProgress}
                />
                <ResponseModal 
                    isModalOpen={ this.state.isResponseModalOpened }
                    createNewConfiguration={ this.createNewConfiguration }
                    goBackToConfigurationList={ this.goBackToConfigurationList }
                    idConfiguration={ this.state.idConfiguration }
                    receiptRows={customer_data ? customer_data.receipt_data : undefined}
                    activityName={ typeof this.state.configuration_data.customer_module == "undefined" ? undefined : this.state.configuration_data.customer_module.activity_name }
                    businessName={ typeof this.state.configuration_data.customer_module == "undefined" ? undefined : this.state.configuration_data.customer_module.business_name }
                    vatCode={ typeof this.state.configuration_data.customer_module == "undefined" ? undefined : this.state.configuration_data.customer_module.vat_code }
                    fiscalCode={ typeof this.state.configuration_data.customer_module == "undefined" ? undefined : this.state.configuration_data.customer_module.fiscal_code }
                />

				<Dialog toggle={ () => {
					this.setState({
						showDialog: !this.state.showDialog
					})
				}} 
				onSuccess={() => {
						try {
							console.log("this.props", this.props);
							addService(this.props.matricolaAutoConfig, "PLAN");
							this.saveFormData();
						}
						catch(error) {
							console.error(error);
						}
					}
				} 
				onCancel={this.saveFormData}
				isOpen={this.state.showDialog} 
				text={"Su questo dispositivo non è attivo il piano assistenza, vuoi attivarlo?"} />
            </React.Fragment>
        );
    }
}